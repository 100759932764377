import React from 'react'
import SVG from 'react-inlinesvg'

export const loginLayoutStyles = `
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .illustration-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: #fff;
    overflow: hidden;

    @media (max-width: 767px) {
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
    }

    .message {
      position: relative;
      width: 50%;
      min-width: 200px;
      max-width: 300px;
      padding: 40px 35px;
      color: white;
      background: var(--text-primary);
      border-radius: 10px;
      z-index: 1;
    }

    .message-heading {
      margin-bottom: 16px;
      font-family: var(--head-font);
      font-size: 26px;
      font-weight: 500;
    }

    .message-content {
    }

    .logo {
      height: 16px;
      margin-top: 48px;
    }

    .border-tr,
    .border-bl,
    .border-br {
      position: absolute;
      color: var(--text-primary);
      pointer-events: none;
    }

    .border-tr {
      top: -7px;
      right: -7px;
    }

    .border-bl {
      left: -7px;
      bottom: -7px;
    }

    .border-br {
      right: -7px;
      bottom: -7px;
    }
  }

  .form-container {
    flex-basis: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
  }

  form {
    width: 480px;
    max-width: 100%;

    .head-logo {
      margin-bottom: 80px;
    }

    a {
      font-family: inherit;
      font-weight: normal;
    }

    .forgot-password-link {
      position: relative;
      top: -8px;
      color: var(--text-secondary);
    }

    .actions {
      .btn-primary {
        padding: 6px 42px;
      }
    }

    input {
      background: white;
    }
  }
`

export const LoginIllustration = (props) => {
  const { head, body } = props

  return (
    <div className="illustration-container">
      <img src="/images/signup/page-illustration.svg" alt="illustration" />
      <div className="message">
        <SVG src="/images/signup/border-tr.svg" className="border-tr" />
        <SVG src="/images/signup/border-bl.svg" className="border-bl" />
        <SVG src="/images/signup/border-br.svg" className="border-br" />
        <div className="message-heading">{head}</div>
        <div className="message-content">{body}</div>
      </div>
    </div>
  )
}
