import { fetchCurrentUser } from '../services/api'
import { setCurrentUser } from '../services/auth'

export const currencies = ['NOK', 'AUD', 'EUR', 'GBP', 'DKK', 'USD', 'SEK']

const storageKey = 'currency'

let currentCurrencyMemo = ''

export const getCurrentCurrency = () => {
  if (currentCurrencyMemo) return currentCurrencyMemo
  return (currentCurrencyMemo = localStorage.getItem(storageKey) || 'USD')
}

export const setCurrentCurrency = (newCurrency) => {
  currentCurrencyMemo = newCurrency
  localStorage.setItem(storageKey, newCurrency)
  fetchCurrentUser({ currency: newCurrency }).then((response) => {
    if (response?.data?.user) {
      const user = response.data?.user
      user.advertiser = response.data?.advertiser
      user.websites = response.data?.websites || []
      user.currencyRates = response.data?.currencyRates || {}
      setCurrentUser(user)
    }
  })
}
